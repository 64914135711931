export enum AnalyticsEvents {
  ViewedEvent = "viewed_event",
  BuyButtonClicked = "buy_button_clicked",
}

interface AnalyticsBaseEvent {
  flicket_event_id?: string;
}

interface AnalyticsViewedEvent extends AnalyticsBaseEvent {}
interface AnalyticsBuyButtonClickedEvent extends AnalyticsBaseEvent {}

// Mapping event IDs to corresponding event interfaces
type EventPayloadMap = {
  [AnalyticsEvents.ViewedEvent]: AnalyticsViewedEvent;
  [AnalyticsEvents.BuyButtonClicked]: AnalyticsBuyButtonClickedEvent;
};

// Utility type to extract the correct event payload based on the event
export type ExtractEventPayload<
  T extends AnalyticsEvents
> = T extends keyof EventPayloadMap ? EventPayloadMap[T] : never;
